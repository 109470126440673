<template>
  <div
    class="modal fade"
    id="modalDetailGoods"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Rincian Daftar Barang</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Nama Barang </label>
              <div class="value">{{ this.detail.goodsName }}</div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Kode Barang</label>
              <div class="value">{{ this.detail.goodsCode }}</div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Qty Sistem</label>
              <div class="value">{{ this.detail.systemQty }}</div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Qty Hasil</label>
              <div class="value">{{ this.detail.resultQty }}</div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Selisih</label>
              <div class="value">{{ this.detail.deviation }}</div>
            </div>

            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Satuan</label>
              <div class="value">{{ this.detail.unit }}</div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="exampleFormControlTextarea1" class="form-label"
                >Alasan</label
              >
              <div class="value">{{ this.detail.reason }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="">
            <h4 class="title">Edit Stock Opname</h4>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/data-opname">Stock Opname </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Edit</li>
              </ol>
            </nav>
          </div>
          <div>
            <router-link :to="{
              name: 'StockResult',
              params: {
                kode: formData.kode
              }
            }" v-if="formData.status_hasil == 'waiting' && checkAccess('inventory', 'approval_stock_opname')" type="button" class="btn btn-edit">Setujui Hasil</router-link>
          </div>
        </div>

        <div class="card-table info-detail">
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a
                class="nav-link active"
                id="pills-home-tab"
                data-toggle="pill"
                href="#pills-home"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                >Perintah</a
              >
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-profile-tab"
                data-toggle="pill"
                href="#pills-profile"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                >Hasil</a
              >
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div class="row mt-3 align-items-center">
                <div class="col-9">
                  <div class="title">Edit Stock Opname</div>
                  <div class="sub-title">Edit informasi yang diperlukan</div>
                </div>
                <div class="col-3">
                  <div class="orange-bedge" v-if="formData.status_hasil == 'waiting'">
                      Menunggu
                  </div>
                  <div class="red-bedge" v-else>Selesai</div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label>Nomor</label>
                    <input
                      type="text"
                      disabled
                      v-model="formData.kode"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="inputAddress2">Departemen :</label>
                    <Select2
                      v-model="formData.id_divisi"
                      :options="OptionDepartement"
                      placeholder="Pilih Departemen"
                      @change="formChange('id_divisi')"
                      @select="formChange('id_divisi')"
                      disabled
                      :class="{
                        'is-invalid': formError && formError.id_divisi,
                      }"
                    />
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="inputAddress2">Tanggal Perintah :</label>
                    <Datepicker
                      :autoApply="true"
                      :closeOnScroll="true"
                      v-model="formData.tanggal_perintah"
                      placeholder="Tanggal Perintah"
                      :format="format"
                      :enableTimePicker="false"
                      locale="id-ID"
                      selectText="Pilih"
                      cancelText="Batal"
                      disabled
                      :class="{
                        'is-invalid': formError && formError.tanggal_perintah,
                      }"
                      @update:modelValue="
                        changeDate($event, 'tanggal_perintah')
                      "
                    ></Datepicker>
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="inputAddress2">Tanggal Pelaksanaan :</label>
                    <Datepicker
                      :autoApply="true"
                      :closeOnScroll="true"
                      v-model="formData.tanggal_pelaksanaan"
                      placeholder="Tanggal Pelaksanaan"
                      disabled
                      :format="format"
                      :enableTimePicker="false"
                      locale="id-ID"
                      selectText="Pilih"
                      cancelText="Batal"
                      :class="{
                        'is-invalid':
                          formError && formError.tanggal_pelaksanaan,
                      }"
                      @update:modelValue="
                        changeDate($event, 'tanggal_pelaksanaan')
                      "
                    ></Datepicker>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label class="" for="inlineFormInputGroupUsername"
                      >Penanggung Jawab :</label
                    >
                    <Select2
                      disabled
                      v-model="formData.karyawan_perintah"
                      :options="OptionPersonResponsible"
                      placeholder="Pilih Penanggung Jawab"
                      @change="formChange('karyawan_perintah')"
                      @select="formChange('karyawan_perintah')"
                      :class="{
                        'is-invalid': formError && formError.karyawan_perintah,
                      }"
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label class="" for="inlineFormInputGroupUsername"
                      >Pelaksana :</label
                    >
                    <Select2
                      v-model="formData.karyawan_pelaksana"
                      :options="OptionEmployeImplementation"
                      placeholder="Pilih Pelaksana"
                      disabled
                      @change="formChange('karyawan_pelaksana')"
                      @select="formChange('karyawan_pelaksana')"
                      :class="{
                        'is-invalid': formError && formError.karyawan_pelaksana,
                      }"
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="inputAddress2">Gudang :</label>
                    <Select2
                      v-model="formData.id_gudang"
                      :options="OptionWerhouse"
                      placeholder="Pilih Gudang"
                      @change="formChange('id_gudang')"
                      @select="formChange('id_gudang')"
                      disabled
                      :class="{
                        'is-invalid': formError && formError.id_gudang,
                      }"
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label class="" for="inlineFormInputGroupUsername"
                      >Keterangan :</label
                    >
                    <textarea
                      type="text"
                      class="form-control"
                      disabled
                      v-model="formData.keterangan"
                      placeholder="Masukkan Keterangan"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div class="row align-items-center mt-4">
                <div class="col-9">
                  <div class="title">Daftar Barang</div>
                  <div class="sub-title">
                    Rincian dari Daftar Barang yang diperlukan
                  </div>
                </div>
                <div class="col-3">
                  <div class="orange-bedge" v-if="formData.status_hasil == 'waiting'">
                      Menunggu
                  </div>
                  <div class="red-bedge" v-else>Selesai</div>
                </div>
              </div>
              <hr />
              <div class="table-responsive">
                <table
                  class="table table-bordered table-striped table-hover"
                  id="tablePO"
                >
                  <thead>
                    <tr>
                      <th style="width: 10%">Kode</th>
                      <th style="width: 35%">Nama Barang</th>
                      <th style="width: 10%">Qty Sistem</th>
                      <th style="width: 10%">Qty Hasil</th>
                      <th style="width: 10%">Selisih</th>
                      <th style="width: 8%">Satuan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in listDetail"
                      :key="index"
                      v-on:click="Showdetail(value)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Klik untuk melihat detail"
                    >
                      <td style="width: 10%">{{ value.kode_barang }}</td>
                      <td style="width: 30%">
                        {{ value.nama_barang }}
                      </td>
                      <td style="width: 10%">{{ value.qty_sistem }}</td>
                      <td style="width: 10%">{{ value.qty_hasil }}</td>
                      <td style="width: 10%">{{ Math.abs(value.selisih) }}</td>
                      <td style="width: 10%">{{ value.nama_satuan }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import { checkModuleAccess, checkRules, cksClient, showAlert } from "../../../../../helper";
import { get_ListDivisi } from "../../../../../actions/master";
import { get_GudangList } from "../../../../../actions/company/gudang";
import { get_KaryawanList } from "../../../../../actions/karyawan";
import {
  post_StockOpnameSave,
  get_StockOpnameDetail,
} from "../../../../../actions/barang/stockOpname";
import moment from "moment";
import "moment/locale/id";

import $ from "jquery";

// import TableLite from 'vue3-table-lite'
export default {
  name: "DetailOpname",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    // TableLite
  },

  data() {
    return {
      checkAccess: checkModuleAccess,
      OptionWerhouse: [],
      OptionDepartement: [],
      OptionPersonResponsible: [],
      OptionEmployeImplementation: [],
      isSubmit: false,
      id_company: cksClient().get("_account").id_company,
      labelPage: this.$route.params.kode ? "Edit" : "Tambah",
      //FORMDATA
      formData: {
        kode: this.$route.params.kode ? this.$route.params.kode : "",
        id_company: cksClient().get("_account").id_company,
        id_divisi: "",
        id_gudang: "",
        tanggal_perintah: "",
        tanggal_pelaksanaan: "",
        karyawan_perintah: "",
        karyawan_pelaksana: "",
        keterangan: "",
        status: "aktif",
        status_hasil: "waiting"
      },
      formError: [],
      rules: {
        id_divisi: {
          required: false,
        },
        id_gudang: {
          required: true,
        },
        tanggal_perintah: {
          required: true,
        },
        tanggal_pelaksanaan: {
          required: true,
        },
        karyawan_perintah: {
          required: true,
        },
        karyawan_pelaksana: {
          required: true,
        },
        keterangan: {
          required: false,
        },
        status: {
          required: false,
        },
      },
      //hasil
      listDetail: [],
      detail: {
        goodsName: "",
        goodsCode: "",
        systemQty: "",
        resultQty: "",
        deviation: "",
        unit: "",
        reason: "",
      },
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  created() {
    this.getDivisi();
    this.getKaryawan();
    this.getGudang();
    if (this.labelPage == "Edit") {
      this.getData();
    }
  },

  mounted() {
    setTimeout(() => {
      $("#pills-home-tab").tab("show");
    }, 600);
  },

  unmounted() {
    $("#pills-home-tab").tab("show");
  },

  methods: {
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },
    Showdetail(value) {
      this.detail = {
        goodsName: value.nama_barang,
        goodsCode: value.kode_barang,
        systemQty: value.qty_sistem,
        resultQty: value.qty_hasil,
        deviation: value.selisih,
        unit: value.nama_satuan,
        reason: value.keterangan,
      };
      $("#modalDetailGoods").modal("show");
    },
    addHasil() {
      this.$router.push({
        name: "StockResult",
        params: {
          kode: this.formData.kode,
        },
      });
    },
    getData() {
      get_StockOpnameDetail(
        this.formData.kode,
        (res) => {
          var { data, detail } = res;
          delete data.created_at;
          delete data.updated_at;
          data.tanggal_perintah = data.tanggal_perintah
            ? moment(data.tanggal_perintah).format("YYYY-MM-DD")
            : "";
          data.tanggal_pelaksanaan = data.tanggal_pelaksanaan
            ? moment(data.tanggal_pelaksanaan).format("YYYY-MM-DD")
            : "";
          this.formData = data;
          this.listDetail = detail;
          setTimeout(() => {
            this.tooltip();
          }, 500);
        },
        () => {
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Data tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataOpname",
              });
            },
          });
        }
      );
    },
    //MASTER
    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.OptionDepartement = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.OptionDepartement.push(element);
          }
        }
      });
    },
    getGudang() {
      get_GudangList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          var { list } = res;
          this.OptionWerhouse = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.OptionWerhouse.push({
                id: element.id,
                text: element.nama,
                alamat: element.alamat,
              });
            }
          }
        }
      );
    },
    getKaryawan() {
      get_KaryawanList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          var { list } = res;
          var data = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
          this.OptionPersonResponsible = data;
          this.OptionEmployeImplementation = data;
        }
      );
    },
    changeDate(data, key) {
      this.formData[key] = moment(data).format("YYYY-MM-DD");
      this.formChange(key);
    },
    async formChange(key) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },

    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        this.isSubmit = true;
        post_StockOpnameSave(
          this.formData,
          (res) => {
            this.isSubmit = false;
            var msg =
              res.response_code === 201
                ? "Perintah stock opname berhasil dicatat"
                : "Perintah stock opname berhasil diperbarui";
            showAlert(this.$swal, {
              title: "BERHASIL!",
              msg: msg,
              onSubmit: () => {
                this.$router.push({
                  name: "DataOpname",
                });
              },
            });
          },
          () => {
            this.isSubmit = false;
            showAlert(this.$swal, {
              title: "GAGAL!",
              msg: "Terjadi kesalahan, silakan ulangi kembali",
              showCancelButton: true,
              showConfirmButton: false,
            });
          }
        );
      }
    },
  },
};
</script>

<style scoped src="../../../../../assets/css/style.css"></style>

<style scoped src="../../../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.value {
  font-size: 13px;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
</style>
